@import "variables";
@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&family=Lato:wght@700&display=swap');

* {
    box-sizing: border-box;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

html {
    text-rendering: optimizeLegibility;
}

body {
    font-family: 'Arimo', sans-serif;
    margin: 0;
    background: $color-background;
    text-align: left;
    max-width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}

h1 {
    font-family: 'Lato', sans-serif;
    color: $color-text-primary;
    font-weight: 700;
    font-size: 20px;
    margin: 0;
}

h2 {
    font-family: 'Lato', sans-serif;
    color: $color-text-primary;
    font-weight: 700;
    font-size: 18px;
    margin-top: 16px;
    margin-bottom: 0;
    &:first-of-type {
        margin-top: 8px;
    }
}

label {
    font-family: 'Arimo', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: $color-text-secondary;
    display: block;
    margin-bottom: 8px;
    margin-left: 4px;
}
input {
    width: 96px;
    height: 48px;
    font-family: 'Arimo', sans-serif;
    background-color: $color-background;
    color: $color-text-primary;
    font-size: 16px;
    border: 2px solid $color-background;
    border-radius: 4px;
    padding: 16px;
    &::placeholder {
        color: $color-text-secondary;
    }
    &:focus {
        outline: none;
    }
}
button {
    user-select: none;
    margin-top: 32px;
    width: 100%;
    background-color: $color-primary;
    border: 2px solid $color-primary;
    border-radius: 4px;
    color: $color-text-primary-inverse;
    padding: 16px 32px;
    font-family: 'Arimo', sans-serif;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 1px;
    transition: ease 100ms;
    &:hover {
        cursor: pointer;
    }
    &:active {
        transform: scale(0.95);
    }
    &:focus {
        outline: none;
    }
}
.buttonAlt {
    padding: 12px 16px;
    background: none;
    border: none;
    color: $color-primary;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    &:hover {
        cursor: pointer;
        background-color: rgba($color-primary, 0.2);
    }
    &:active {
        transform: scale(0.95);
    }
}
p {
    padding: 0;
    color: $color-text-primary;
    font-size: 14px;
    font-weight: 400;
}

a {
    text-decoration: none;
    user-select: none;
}

ul {
    list-style-type: none;
}
.containerMain {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}
.card {
    margin: 16px 16px 0 16px;
    width: 100%;
    height: 100%;
    padding: 24px;
    background-color: $color-background-light;
    border-radius: 8px;
    &:last-of-type {
        margin-bottom: 16px;
    }
}
@media (min-width: 512px) {
    .screen {
        margin-top: 128px;
    }
    .card {
        width: 400px;
        height: auto;
    }
}
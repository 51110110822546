@import "src/variables";

.content {
    width: 100%;
}
.horizontal {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.placeholder {
    width: 64px;
}
.link {
    padding: 0;
    color: $color-primary;
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
    &:hover {
        cursor: pointer;
    }
}
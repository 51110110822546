@import "src/variables";

.toolbar {
    display: flex;
    align-items: center;
    justify-content: left;
}
.iconClick{
    padding: 8px;
    margin-right: 24px;
    &:hover {
        cursor: pointer;
    }
}
.icon {
    color: $color-text-primary;
    font-size: 24px;   
}
.result {
    margin-top: 16px;
    padding: 16px;
    background-color: rgba($color-primary, 0.1);
    border: 3px solid transparent;
    border-radius: 4px;
    p {
        margin: 0;
        color: $color-primary;
    }
    .type {
        font-weight: 700;
        text-transform: uppercase;
    }
    .text {
        margin-top: 4px;
        font-weight: 400;
    }
    .product {
        margin-top: 4px;
    }
}
.center {
    margin-top: 32px;
    text-align: center;
}
@import "src/variables";

.snackbar {
    z-index: 10;
    position: fixed;
    bottom: 56px;
    width: 80%;
    max-width: 256px;
    padding: 16px;
    margin: 0;
    background-color: rgba(#323232, 1);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        margin: 0;
        flex-grow: 1;
        color: $color-text-primary-inverse;
    }
    button {
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0;
        padding: 0;
        background: none;
        border: none;
        color: $color-secondary;
        width: fit-content;
    }
}